<template>
  <div>
    <vl-region tag-name="section">
      <vl-layout>
        {{ error }}
        <div class="overlay">
          <a :href="paymentLink" class="parent" v-if="paymentLink">
            <img class="pomTemplate" src="@/assets/pom.png" />
            <VueQrcode
              class="qrCode"
              v-if="paymentLink"
              :value="paymentLink"
              :options="{ width: 195 }"
            ></VueQrcode>
            <div class="link">
              <p>{{ paymentLink }}</p>
            </div>
          </a>
        </div>
      </vl-layout>
    </vl-region>
  </div>
</template>

<script>
import VueQrcode from '@chenfengyuan/vue-qrcode';

export default {
  components: {
    VueQrcode,
  },
  data() {
    return {
      paymentLink: '',
      error: '',
    };
  },
  async created() {
    try {
      this.$store.dispatch('paylink/get', this.$route.query.uuid).then(payment => {
        this.paymentLink = payment.paylink;
      });
    } catch (err) {
      this.error = err.message;
    }
  },
};
</script>
<style>
label {
  font-size: 1.5rem;
}
.red {
  color: red;
}
span,
li {
  font-size: 1.5rem;
}
.parent {
  margin-top: 10px;
  position: relative;
  top: 0;
  left: 0;
  display: table;
}
.pomTemplate {
  height: 260px;
  position: relative;
  top: 0;
  left: 0;
}
.qrCode {
  position: absolute;
  top: 5%;
  left: 67.4%;
}
.link {
  position: absolute;
  bottom: 35px;
  right: 38%;
}
p {
  text-align: right;
  font-size: 15px;
  color: black;
}
.overlay:hover {
  opacity: 0.5;
  transition: 0.2s;
}
.overlay {
  display: table;
  transition: 0.2s;
}
</style>
